






































































































@import '~@sum.cumo/vue-datepicker/src/styles/style.scss';
@import '../../utilities/config';
.a-date {
  &__input {
    position: relative;
    display: block;
    input {
      padding-right: 42px;
      &::placeholder {
        color: gray('gray-150');
        font-weight: 300;
      }
    }
    &__icon {
      cursor: pointer;
      width: 22px;
      height: 22px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0, -50%);
      background-image: url(../../assets/calendar_dark_green.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .form-control[readonly] {
    background-color: gray('white');
  }
}

.vdp-datepicker {
  &__calendar {
    .cell:not(.blank):not(.disabled).day:hover,
    .cell:not(.blank):not(.disabled).month:hover,
    .cell:not(.blank):not(.disabled).year:hover {
      border: 1px solid darken(theme-color('primary'), 0.3);
      background-color: theme-color('faded-green');
      &.selected {
        background-color: theme-color('primary');
        font-weight: 500; //font-weight: 400;;
        color: gray('white');
        &:hover {
          background-color: darken(theme-color('primary'), 0.3);
        }
      }
    }
    .cell {
      border-radius: 40px;
      &.selected {
        background-color: theme-color('primary');
        font-weight: 500; //font-weight: 400;;
        color: gray('white');
      }
    }
  }
}

.was-validated .form-control:invalid {
  background-image: none;
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}
